//通用弹窗

import { ElMessage } from 'element-plus'

const tips = class{
	constructor(message, code = 0){
		this.message = message
		this.code = code
	}
	
	show_tips(){
		let type = ''
		switch(this.code){
			case 0:
				type = 'success'
				break;
			case 1:
				type = 'error'
				break;
			case 2:
				type = 'warning'
				break;
			default:
				type = 'info'
				break;
		}
		
		 ElMessage({
			message: this.message,
			type: type,
			duration: 1500
		  })
	}
}
export default tips
