//axios请求

import axios from "axios"
import instance from '../api/header.js'

const request = class{
	constructor(url, arg){
		this.url = url
		this.arg = arg
	}
	
	//get请求
	mode_get(){
		return new Promise((resolve, reject)=>{
			instance.get((this.url))
			.then(res => {
				//成功
				resolve(res)
			})
			.catch(err=>{
				//50*错误
				reject(err)
			})
		})
	}
	
	//post请求
	mode_post(){
		return new Promise((resolve, reject)=>{
			instance.post((this.url), this.arg)
			.then(res => {
				//成功
				resolve(res)
			})
			.catch(err => {
				//50*错误
				reject(err)
			})
		})
	}
}

export default request

