//放置所有请求接口

const url = "http://lanj-mnt.sta.cc/api"

const urls = class{
	static m(){
		//登录接口
		const login = `${url}/login`
		
		return {
			login
		}
	}
}

export default urls