import { createStore } from 'vuex'

const store = createStore({
	
	//状态管理器中管理的数据源
	state:{
		currentMenuName:""
	},
	
	//同步操作
	mutations:{
		setCurrentMenuName(state, data){
			state.currentMenuName = data
		}
	},
	
	//异步操作
	actions:{
		
	},
	
	//计算属性
	getters:{
		getCurrentMenuName(state){
			return state.currentMenuName
		}
	}
})


export default store