import axios from "axios"
import { Base64 } from "js-base64"
import {ElMessageBox, ElMessage} from 'element-plus'

//创建通用axios配置
let instance = axios.create({
	responseType:"json",
	headers:{'Content-Type':'application/json'},
	timeout:3000
})

//对token进行加密
function baseFn(){
	const token = localStorage.getItem("token")
	const base64 = Base64.encode(token + ':')
	return 'Basic '+ base64
}

//http请求拦截 axios请求之前 在axios请求之前给每个接口携带token去后端校验身份
instance.interceptors.request.use(
	config => {
		let token = localStorage.getItem("token")
		if(token) config.headers.Authorization = baseFn()
		return config
	},
	err => {
		return Promise.reject(err)
	}
)

// http请求拦截 axios请求之后
instance.interceptors.response.use(
	response => {
			let status = response.status
			
			let code = response.data.code
			let msg = response.data.msg
			console.log('status1:'+status+ ' code:'+code+ ' msg:'+msg)
			// switch(code){
			// 	case 0:
			// 		ElMessage({
			// 			message: msg,
			// 			type: 'success',
			// 		})
			// 	break;
				
			// 	//没有访问权限 token过期 未携带token token错误
			// 	case 1:
			// 		ElMessageBox.alert(msg, '温馨提示', {
			// 			confirmButtonText: '好的',
			// 			type: 'error',
			// 		})
			// 		.then(res=>{
			// 			  //确定按钮操作
			// 		})
			// 		.catch(err=>{
			// 			  //关闭提醒框/取消操作
			// 		})
			// 		break;
			// }
		return response.data
	},
	error => {
		console.log(error.response.status)
		let msg = "服务异常，请稍后再试！";
		if (error.message === 'Network Error') {
			ElMessageBox.alert('网络错误，请检查网络连接', '温馨提示', {
				confirmButtonText: '好的',
				type: 'error'
			})
			return false
		}
		
		if(error.response != undefined ){
			let status = error.response.status
			msg = error.response.data.msg
			//console.log('status:'+status+ ' msg:'+msg+ ' msg:'+error_msg)
			
			ElMessageBox.alert(msg, '温馨提示', {
				confirmButtonText: '好的',
				type: 'error',
			})
			.then(res=>{//确定按钮操作
			
			})
			.catch(err=>{ //关闭提醒框/取消操作
			
			})
			return false
			
		}
		//返回错误信息
		return Promise.reject(msg)
	}
)

export default instance