import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
	{  //登录页面
		path: '/',
		name: 'login',
		component:()=>import(/*webpackChunkName:'Login'*/ '@/page/login/login')
	},
	{  //主界面
		path: '/index',
		name: 'index',
		component:()=>import('@/page/index/index'),
		redirect:'/dashboard',
		children:[
			{
				//控制台
				path: '/dashboard',
				name: '控制台',
				component:()=>import('@/page/index/dashboard'),
			},
			{
				//视频管理
				path: '/videos',
				name: '视频管理',
				component:()=>import('@/page/videos/index'),
			},
			{
				//店铺管理
				path: '/store',
				name: '店铺管理',
				component:()=>import('@/page/store/index'),
			},
			{
				//Mqtt管理
				path: '/mqtt',
				name: 'Mqtt管理',
				component:()=>import('@/page/mqtt/index'),
			},
			{
				//权限管理
				path: '/auth',
				name: '权限管理',
				redirect:'/auth-admin',
				children:[
					{
						//管理员管理
						path: '/auth-admin',
						name: '管理员管理',
						component:()=>import('@/page/auth/index'),
					},
					{
						//角色管理
						path: '/auth-role',
						name: '角色管理',
						component:()=>import('@/page/auth/role'),
					},
					{
						//功能管理
						path: '/auth-node',
						name: '功能管理',
						component:()=>import('@/page/auth/node'),
					}
				]
			},
			{
				//日志管理
				path: '/log',
				name: '日志管理',
				redirect:'/log-store',
				children:[
					{
						//店铺日志管理
						path: '/log-store',
						name: '店铺日志管理',
						component:()=>import('@/page/log/store'),
					},
					{
						//客服日志管理
						path: '/log-kefu',
						name: '客服日志管理',
						component:()=>import('@/page/log/kefu'),
					},
					{
						//推流日志管理
						path: '/log-plug-flow',
						name: '推流日志管理',
						component:()=>import('@/page/log/plug_flow'),
					},
					{
						//操作日志管理
						path: '/log-handle',
						name: '操作日志管理',
						component:()=>import('@/page/log/handle'),
					}
				]
			},
			{
				//渠道管理
				path: '/channel',
				name: '渠道管理',
				component:()=>import('@/page/channel/index'),
			},
			{
				//系统设置
				path: '/setting',
				name: '系统设置',
				component:()=>import('@/page/setting/index'),
			},
			{
				//工作台
				path: '/worktable',
				name: '工作台',
				component:()=>import('@/page/worktable/index'),
			}
		]
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
